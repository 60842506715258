interface State<T> {
  open: boolean;
  entityToUpdate: T;
}

type ActionType<T> = { type: 'OPEN_DIALOG'; payload: T } | { type: 'CLOSE_DIALOG' };

export const initialState = <T,>(): State<T> => ({
  open: false,
  entityToUpdate: {} as T,
});

export const reducer = <T,>(state: State<T>, action: ActionType<T>): State<T> => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return { ...state, open: true, entityToUpdate: action.payload };
    case 'CLOSE_DIALOG':
      return { ...state, open: false, entityToUpdate: {} as T };
    default:
      return state;
  }
};
