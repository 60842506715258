import React, { useReducer, useState } from 'react';
import { useGetOauthQuery } from '../../state/api';
import { AddOutlined, DeleteOutlined, EditOutlined, PlayCircleOutline } from '@mui/icons-material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import Header from '../../components/Header';
import CreateOauthDialogue from '../../components/CreateOauthDialogue';
import { AlertColor, Box, Button, IconButton, Tooltip, useTheme } from '@mui/material';
import { Oauth } from 'models/index.model';
import { initialState, reducer } from '../../utils/dialogState';
import { generateToken, useUserPermissions } from 'utils/utils';
import { sec } from 'auth/accessToken';
import axios, { AxiosResponse } from 'axios';
import CustomDataGrid from 'components/CustomDataGrid';
import { useNavigate } from 'react-router-dom';
import AlertSnackbar from 'components/AlertSnackbar';

const Oauths = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userPermissions = useUserPermissions();
  const hasReadMasterDataPermission = userPermissions.includes('read:access_token');
  const hasCreateMasterDataPermission = userPermissions.includes('create:access_token');
  const hasUpdateMasterDataPermission = userPermissions.includes('update:access_token');
  const hasDeleteMasterDataPermission = userPermissions.includes('delete:access_token');

  if (Array.isArray(userPermissions) && userPermissions.length > 0 && !hasReadMasterDataPermission) {
    // TODO: change to permission denied page in the future
    navigate('/');
  }

  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Oauth>, initialState<Oauth>());

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Oauth });
  };

  const handleClickOpenEdit = (oauth: Oauth) => {
    dispatch({ type: 'OPEN_DIALOG', payload: oauth });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const defaultSnackbarState = { open: false, message: '', severity: 'success' as AlertColor };
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: AlertColor }>(
    defaultSnackbarState,
  );
  const handleSnackbarClose = () => {
    setSnackbar(defaultSnackbarState);
  };

  const generateCode = async (oauth: Oauth) => {
    if (typeof oauth.authUrl === 'undefined' || oauth.authUrl === '') {
      try {
        const res: AxiosResponse = await generateToken(oauth._id);
        if (res.status === 200) {
          setSnackbar({ open: true, message: 'Successfully created token', severity: 'success' });
        } else {
          throw new Error('Unable to create token');
        }
      } catch (e) {
        setSnackbar({ open: true, message: 'Unable to create token', severity: 'error' });
      }
      return;
    }
    const authUrl = new URL(oauth.authUrl);
    const params = new URLSearchParams([
      ...Object.entries({
        response_type: 'code',
        client_id: oauth.clientId,
        redirect_uri: oauth.redirectUrl,
        scope: oauth.scopes.join('+'),
        state: oauth._id,
      }),
    ]);
    authUrl.search = params.toString();
    const win = window.open(authUrl, '_blank');
    if (win === null) {
      return;
    }
    win.focus();
  };

  const { data: oauthList, isLoading, refetch } = useGetOauthQuery();

  const deleteOauth = async (oauthId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/oauth/${oauthId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'provider',
      headerName: 'Provider',
      flex: 1,
    },
    {
      field: 'clientId',
      headerName: 'Client ID',
      flex: 1,
    },
    {
      field: 'clientSecret',
      headerName: 'Client Secret',
      flex: 1,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues: GridCellParams) => {
        return (
          <Box display={'flex'} justifyContent={'space-around'}>
            {hasUpdateMasterDataPermission && (
              <>
                <Tooltip title='Generate Token'>
                  <IconButton
                    color='primary'
                    onClick={() => {
                      generateCode(cellValues.row);
                    }}
                  >
                    <PlayCircleOutline />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Edit'>
                  <IconButton
                    color='primary'
                    onClick={() => {
                      handleClickOpenEdit(cellValues.row);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {hasDeleteMasterDataPermission && (
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => {
                    deleteOauth(cellValues.row._id);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Header title='Oauth' />
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Oauth
            </Button>
          )}
          <CreateOauthDialogue handleClose={handleClose} open={open} oauthToUpdate={entityToUpdate} />
        </Box>
      </Box>
      <CustomDataGrid data={oauthList} columns={columns} isLoading={isLoading} />
      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleSnackbarClose}
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Oauths;
