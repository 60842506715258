import React from 'react';
import { useGetDeadlinesQuery } from '../../state/api';
import { GridColDef } from '@mui/x-data-grid';
import Header from '../../components/Header';
import { Box, IconButton, Typography, Tooltip, TextField, Autocomplete } from '@mui/material';
import { useNavigate, generatePath } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DeadlineType } from 'models/booking.model';
import { setBookingTeams, useUserPermissions } from 'utils/utils';
import CustomDataGrid from 'components/CustomDataGrid';
import { RefreshOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDeadlinesFilters } from 'state';

const Deadlines = () => {
  const navigate = useNavigate();
  const dispatchDealinesState = useDispatch();

  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('read:deadlines-export');
  const hasImportPermission = userPermissions.includes('read:deadlines-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);

  const { startDate, endDate, bookingTeam } = useSelector((state: any) => state.global.deadlinesFilters);

  const useGetDeadline = (deadlineType: DeadlineType) => {
    const { data, isLoading, refetch, isFetching } = useGetDeadlinesQuery(
      [deadlineType, [startDate, endDate], hasExportPermission, hasImportPermission, bookingTeam],
      {
        refetchOnMountOrArgChange: true,
      },
    );

    return {
      deadlines: data,
      isLoading: isLoading,
      refetch: refetch,
      isFetching: isFetching,
    };
  };

  const deadlinesSI = useGetDeadline(DeadlineType.SI);
  const deadlinesVGM = useGetDeadline(DeadlineType.VGM);
  const deadlinesUCR = useGetDeadline(DeadlineType.UCR);
  const deadlinesGateIn = useGetDeadline(DeadlineType.GateIn);

  const refetchAll = () => {
    deadlinesSI.refetch();
    deadlinesVGM.refetch();
    deadlinesUCR.refetch();
    deadlinesGateIn.refetch();
  };

  const columns: GridColDef[] = [
    {
      field: 'bookingNumber',
      headerName: 'Booking Number',
      flex: 0.3,
    },
    {
      field: 'consignor',
      headerName: 'Consignor',
      flex: 0.5,
      valueGetter: params => {
        return params.row.consignor?.name;
      },
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 0.3,
      valueGetter: params => {
        return params.row.carrier?.name;
      },
    },
    {
      field: 'carrierBookingNumber',
      headerName: 'Carrier Booking No',
      flex: 0.3,
    },
    {
      field: 'deadlineDate',
      headerName: 'Date',
      flex: 0.2,
      valueGetter: params => {
        return dayjs(params.row.deadline).format('DD/MM/YYYY');
      },
    },
    {
      field: 'deadlineTime',
      headerName: 'Time',
      flex: 0.2,
      valueGetter: params => {
        return dayjs(params.row.deadline).format('HH:mm');
      },
    },
    {
      field: 'Details',
      renderCell: cellValues => {
        return (
          <Box>
            <Tooltip title='View Booking'>
              <IconButton
                color='primary'
                onClick={() => {
                  navigate(
                    generatePath(`/bookings/${cellValues.row._id}`, {
                      id: cellValues.row._id,
                    }),
                  );
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      flex: 0.2,
    },
  ];

  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Header title='Deadlines' />
        <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
          <Tooltip title='Refresh'>
            <IconButton onClick={() => refetchAll()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          <Autocomplete
            options={teams ?? []}
            isOptionEqualToValue={(option, value) => option === value}
            value={bookingTeam}
            onChange={(e, newValue) => {
              if (newValue) {
                dispatchDealinesState(setDeadlinesFilters({ bookingTeam: newValue }));
              }
            }}
            renderInput={params => <TextField {...params} label='Team' sx={{ width: '9rem' }} />}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '9rem' }}
              label='Start Date'
              format='DD/MM/YYYY'
              value={dayjs(startDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchDealinesState(setDeadlinesFilters({ startDate: newDate.toISOString() }));
                }
              }}
            />
            <DatePicker
              sx={{ width: '9rem' }}
              label='End Date'
              format='DD/MM/YYYY'
              value={dayjs(endDate)}
              onChange={(newDate: dayjs.Dayjs | null) => {
                if (newDate && newDate.isValid()) {
                  dispatchDealinesState(setDeadlinesFilters({ endDate: newDate.toISOString() }));
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'1rem'}>
        <Box>
          <Typography variant='h4' sx={{ mb: '1rem' }}>
            SI Deadlines
          </Typography>
          <CustomDataGrid
            data={deadlinesSI.deadlines}
            columns={columns}
            isLoading={deadlinesSI.isLoading}
            isFetching={deadlinesSI.isFetching}
            showToolbar={false}
            height='32vh'
          />
        </Box>
        <Box>
          <Typography variant='h4' sx={{ mb: '1rem' }}>
            VGM Deadlines
          </Typography>
          <CustomDataGrid
            data={deadlinesVGM.deadlines}
            columns={columns}
            isLoading={deadlinesVGM.isLoading}
            isFetching={deadlinesVGM.isFetching}
            showToolbar={false}
            height='32vh'
          />
        </Box>
        <Box>
          <Typography variant='h4' sx={{ mb: '1rem' }}>
            UCR Deadlines
          </Typography>
          <CustomDataGrid
            data={deadlinesUCR.deadlines}
            columns={columns}
            isLoading={deadlinesUCR.isLoading}
            isFetching={deadlinesUCR.isFetching}
            showToolbar={false}
            height='32vh'
          />
        </Box>
        <Box>
          <Typography variant='h4' sx={{ mb: '1rem' }}>
            Gate In Deadlines
          </Typography>
          <CustomDataGrid
            data={deadlinesGateIn.deadlines}
            columns={columns}
            isLoading={deadlinesGateIn.isLoading}
            isFetching={deadlinesGateIn.isFetching}
            showToolbar={false}
            height='32vh'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Deadlines;
