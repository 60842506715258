import { sec } from 'auth/accessToken';
import axios from 'axios';
import { Booking, StepDetails, TransportSchedule, TransportStatus } from 'models/booking.model';
import { get } from 'utils/utils';

const transportScheduleHasItems = (schedule: TransportSchedule[] | undefined) => !!schedule?.length;

const activeTransportSchedule = (transportSchedule: TransportSchedule[] | undefined) =>
  transportSchedule?.filter(schedule => schedule.status !== TransportStatus.Cancelled);

const getMissingFields = (schedule: TransportSchedule[] | undefined, requiredFields: (keyof TransportSchedule)[]) => {
  const missingFieldsByRow: { [row: number]: string[] } = {};

  schedule?.forEach((item, index) => {
    const missingFields: string[] = requiredFields.filter(field => item[field] === '');
    if (missingFields.length > 0) {
      missingFieldsByRow[index + 1] = missingFields;
    }
  });

  return Object.entries(missingFieldsByRow).map(([row, fields]) => `Schedule ${row}: ${fields.join(', ')}`);
};

export type TSteps = Record<
  string,
  {
    order: number;
    label: string;
    description: string;
    fetchStatus?(booking: Booking): Promise<StepDetails | undefined>;
    dbValues?: string[];
    getConfirmMap?: (booking: Booking) => Promise<Record<string, string>>;
    check?(booking: Booking): Promise<string[]>;
    complete?: {
      // TODO: Review this typing
      withApi?(booking: Booking): Promise<void> | undefined;
      manually?(booking?: Booking): Promise<void> | undefined;
    };
  }
>;

const getConfirmMapFn = (step: TSteps[0]) => async (booking: Booking) => {
  const dbValMap: Record<string, string> = {};
  step?.dbValues?.forEach(val => {
    const arrIdx = val.indexOf('[?]');
    if (arrIdx >= 0) {
      const arrVal = get(booking, val.split('[?].')[0]);
      if (!arrVal || !Array.isArray(arrVal) || arrVal.length <= 0) {
        dbValMap[val.split('[?].')[0]] = 'null';
      }
      (arrVal as []).forEach((v, i) => {
        dbValMap[`${val.split('[?].')[0]}[${i}].${val.split('[?].')[1]}`] = get(v, val.split('[?].')[1]) + '';
      });
    } else {
      dbValMap[val] = get(booking, val) + '';
    }
  });
  return dbValMap;
};

const exportStepsDef: TSteps = {
  CBK: {
    order: 1,
    label: 'Submit booking to carrier',
    description: `Submit booking to appropriate carrier using booking details.`,
    async fetchStatus(booking: Booking): Promise<StepDetails | undefined> {
      const accessToken = await sec.getAccessTokenSilently()();
      const resp = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/booking`,
        {
          id: booking._id,
        },
        {
          params: {
            bookingType: booking.carrier?.name.toLowerCase(),
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return resp.data;
    },
    dbValues: [
      'carrierQuotationReference',
      'etd',
      'containerType',
      'approximateWeight',
      'portOfLoading.portName',
      'portOfDestination.portName',
    ],
    async getConfirmMap(booking: Booking) {
      return getConfirmMapFn(this)(booking);
    },
    async check(booking: Booking) {
      const errors: string[] = [];
      if (!booking.quotationApproval) {
        errors.push('Quotation approval is required');
      }
      if (!transportScheduleHasItems(booking.transportSchedule || [])) {
        errors.push('Transport schedule is required');
      }
      const missingDeadlines = Object.entries(booking.deadlines)
        .filter(([key, value]) => value === 'N/A')
        .map(([key]) => key);
      if (missingDeadlines.length) {
        errors.push(`Missing deadlines: ${missingDeadlines.join(', ')}`);
      }
      if (booking.carrier?.name.toLowerCase().includes('maersk')) {
        errors.concat(
          this.dbValues?.reduce((acc, currentVal) => {
            const originalValue = get(booking, currentVal);
            if (
              typeof originalValue === 'undefined' ||
              originalValue === null ||
              (typeof originalValue === 'string' && originalValue.trim().length <= 0)
            ) {
              acc.push(currentVal + ' is required');
            }
            return acc;
          }, [] as string[]) ?? [],
        );
      }
      return errors;
    },
    complete: {
      async withApi(booking: Booking) {
        const accessToken = await sec.getAccessTokenSilently()();
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/booking`,
          { id: booking._id },
          {
            params: {
              bookingType: booking.carrier?.name.toLowerCase(),
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      },
    },
  },
  VGM: {
    order: 2,
    label: 'Submit VGM',
    description: 'Submit VGM to carrier or Destinate. Add additional VGM details to booking details before submitting.',
    async fetchStatus(booking: Booking): Promise<StepDetails | undefined> {
      const accessToken = await sec.getAccessTokenSilently()();
      return axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/vgm`,
        {
          id: booking._id,
        },
        {
          params: {
            bookingType: booking.carrier?.name.toLowerCase(),
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    async check({ transportSchedule }: Booking) {
      const errors: string[] = [];
      if (!transportScheduleHasItems(transportSchedule || [])) errors.push('Transport schedule is required');
      const missingFields = getMissingFields(activeTransportSchedule(transportSchedule) || [], [
        'containerNumber',
        'weight',
      ]);
      if (missingFields.length) {
        errors.push(`Missing fields: ${missingFields.join(', ')}`);
      }
      return errors;
    },
    complete: {
      async withApi(booking: Booking) {
        const accessToken = await sec.getAccessTokenSilently()();
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/vgm`,
          { id: booking._id },
          {
            params: {
              bookingType: booking.carrier?.name.toLowerCase(),
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      },
    },
  },
  SII: {
    order: 3,
    label: 'Submit SI instructions',
    description: `Submit SI to carrier and add invoice details to booking details before submitting.`,
    async check({ transportSchedule }: Booking) {
      const errors: string[] = [];
      if (!transportScheduleHasItems(transportSchedule || [])) errors.push('Transport schedule is required');
      const missingFields = getMissingFields(activeTransportSchedule(transportSchedule) || [], [
        'sealNumber',
        'containerNumber',
        'weight',
      ]);
      if (missingFields.length) errors.push(`Missing fields: ${missingFields.join(', ')}`);
      return errors;
    },
  },
  UCR: {
    order: 4,
    label: 'Submit UCR',
    description: `Complete customs clearance and send confirmation to carrier and customer.`,
    dbValues: [
      'packages',
      'cargoValue.currency',
      'cargoValue.value',
      'cargoDescription',
      'HSCode',
      'transportSchedule[?].containerNumber',
      'transportSchedule[?].sealNumber',
      'transportSchedule[?].weight',
      'vesselVoyage.vesselName',
      'vesselVoyage.vesselFlag',
      'portOfLoading.portCode',
      'portOfDestination.portCode',
      'consignor.EORI',
      'consignee.name',
      'consignee.address',
      'consignee.city',
      'consignee.country',
      'consignee.postcode',
    ],
    async getConfirmMap(booking: Booking) {
      const originalValue = await getConfirmMapFn(this)(booking);
      if (originalValue['cargoValue.currency'] === 'GBP') {
        return originalValue;
      }
      const accessToken = await sec.getAccessTokenSilently()();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/exchangeRates/?base=${originalValue['cargoValue.currency']}&target=GBP`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      originalValue['cargoValue.value'] = (parseFloat(originalValue['cargoValue.value']) * response.data).toFixed(2);
      originalValue['cargoValue.currency'] = 'GBP';
      return originalValue;
    },
    async check(booking: Booking) {
      const errors: string[] | undefined = this.dbValues?.reduce((acc, currentVal) => {
        const arrIdx = currentVal.indexOf('[?]');
        if (arrIdx >= 0) {
          // we know this is an array
          const arrVal = get(booking, currentVal.split('[?].')[0]);
          if (!arrVal || !Array.isArray(arrVal) || arrVal.length <= 0) {
            const errStr = currentVal.split('[?].')[0] + ' is required';
            if (!acc.includes(errStr)) {
              acc.push(errStr);
            }
          }
          (arrVal as []).forEach(v => {
            const originalValue = get(v, currentVal.split('[?].')[1]);
            if (!originalValue) {
              acc.push(currentVal.split('[?].')[0] + '.' + currentVal.split('[?].')[1] + ' is required');
            }
          });
        } else {
          const originalValue = get(booking, currentVal);
          if (
            typeof originalValue === 'undefined' ||
            originalValue === null ||
            (typeof originalValue === 'string' && originalValue.trim().length <= 0)
          ) {
            acc.push(currentVal + ' is required');
          }
        }
        return acc;
      }, [] as string[]);
      return errors ?? [];
    },
    async fetchStatus(booking: Booking): Promise<StepDetails | undefined> {
      const accessToken = await sec.getAccessTokenSilently()();
      const resp = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/ucr`,
        {
          id: booking._id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      return resp.data;
    },
    complete: {
      async withApi(booking: Booking) {
        const accessToken = await sec.getAccessTokenSilently()();
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/external-api/ucr`,
          { id: booking._id },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      },
    },
  },
  PEI: {
    order: 5,
    label: 'Prepare Invoice',
    description: `Please prepare invoice and send to customer.`,
  },
  DBL: {
    order: 6,
    label: 'Dispatch BL',
    description: `Check all details are correct and dispatch BL to customer.`,
  },
};

const importStepsDef: TSteps = {
  PPI: {
    order: 1,
    label: 'Prepare Purchase Invoice',
    description: `Prepare purchase invoice for approval once recieved from vendors.`,
  },
  PTC: {
    order: 2,
    label: 'Payment to Carrier',
    description: `Complete payment to carrier if we do not have credit`,
  },
  ORP: {
    order: 3,
    label: 'Obtain Release Pin',
    description: `Obtain release pin from carrier or from Destinate and send to hauler.`,
  },
  UCR: {
    order: 4,
    label: 'Submit UCR',
    description: `Complete pre-entry for customs clearance and obtain reference number once vessel as arrived.`,
  },
  PTH: {
    order: 5,
    label: 'Payment to HMRC',
    description: `Completed payment to HMRC for VAT (depending on PVA and Non-PVA customers) and Duty.`,
  },
  PSI: {
    order: 6,
    label: 'Prepare Sales Invoice',
    description: `Prepare sales invoice and send to customer.`,
  },
};

export const exportSteps = Object.entries(exportStepsDef)
  .sort(([_db1, val1], [_db2, val2]) => val1.order - val2.order)
  .map(([dbRef, details]) => ({
    dbRef,
    ...details,
  }));

export const importSteps = Object.entries(importStepsDef)
  .sort(([_db1, val1], [_db2, val2]) => val1.order - val2.order)
  .map(([dbRef, details]) => ({
    dbRef,
    ...details,
  }));
