import React, { useReducer, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { AddOutlined } from '@mui/icons-material';
import Header from '../../components/Header';
import { useGetAuditsQuery, useGetPortsQuery } from '../../state/api';
import { Box, Button, useTheme } from '@mui/material';
import { Port } from 'models/index.model';
import { initialState, reducer } from '../../utils/dialogState';
import { useUserPermissions } from 'utils/utils';
import { generateActionsColumn } from 'utils/generateActionsColumn';
import axios from 'axios';
import { sec } from 'auth/accessToken';
import CustomDataGrid from 'components/CustomDataGrid';
import CreatePortDialogue from 'components/CreatePortDialogue';
import AuditLogsDialogue from 'components/AuditLogsDialogue';

export const Ports = () => {
  const theme = useTheme();

  const userPermissions = useUserPermissions();
  const hasCreateMasterDataPermission = userPermissions.includes('create:masterdata');
  const hasUpdateMasterDataPermission = userPermissions.includes('update:masterdata');
  const hasDeleteMasterDataPermission = userPermissions.includes('delete:masterdata');

  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Port>, initialState<Port>());

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Port });
  };

  const handleClickOpenEdit = (port: Port) => {
    dispatch({ type: 'OPEN_DIALOG', payload: port });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>('');

  const { data: ports, isLoading, refetch } = useGetPortsQuery();
  const { data: auditLogs } = useGetAuditsQuery(['Port', currentMasterDataId]);

  const deletePort = async (portId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/ports/${portId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'portName',
      headerName: 'Port Name',
      flex: 0.3,
    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.3,
    },
    {
      field: 'portCode',
      headerName: 'Port Code',
      flex: 0.3,
    },
  ];

  if (hasUpdateMasterDataPermission || hasDeleteMasterDataPermission) {
    columns.push(
      generateActionsColumn<Port>(
        hasUpdateMasterDataPermission,
        hasDeleteMasterDataPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deletePort,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId,
      ),
    );
  }
  return (
    <Box m='1.5rem 2.5rem'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Header title='Ports' />
        <Box>
          {hasCreateMasterDataPermission && (
            <Button
              variant='contained'
              startIcon={<AddOutlined />}
              onClick={handleClickOpenCreate}
              sx={{
                bgcolor: theme.palette.secondary[400],
                '&:hover': {
                  backgroundColor: theme.palette.secondary[500],
                },
              }}
            >
              Create Port
            </Button>
          )}
          <CreatePortDialogue handleClose={handleClose} open={open} portToUpdate={entityToUpdate} />
        </Box>
      </Box>
      <CustomDataGrid
        data={ports}
        columns={columns}
        isLoading={isLoading}
        sortModel={[{ field: 'portName', sort: 'asc' }]}
      />
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
    </Box>
  );
};

export default Ports;
