import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { StepLabelProps } from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { CheckCircle, Error, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

export interface CustomStepLabelProps extends StepLabelProps {
  label: string;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderStyle: 'dashed',
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    borderWidth: 1,
    borderStyle: 'dashed',
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.secondary[200],
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.secondary[200],
    zIndex: 1,
  },
  '& .QontoStepIcon-errorIcon': {
    color: theme.palette.error.main,
    zIndex: 1,
  },
  '& .QontoStepIcon-circle': {
    display: 'block',
    width: 22,
    height: 22,
    borderRadius: '100%',
    backgroundColor: theme.palette.grey[400],
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundClip: 'content-box',
    padding: 4,
  },
}));

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className, error, icon } = props;
  if (React.isValidElement(icon)) {
    return icon;
  }
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {error ? (
        <Error className='QontoStepIcon-errorIcon' />
      ) : completed ? (
        <CheckCircle className='QontoStepIcon-completedIcon' />
      ) : active ? (
        <RadioButtonChecked />
      ) : (
        <RadioButtonUnchecked />
      )}
    </QontoStepIconRoot>
  );
};

export default function BaseStepper({ activeStep, steps }: { activeStep: number; steps: CustomStepLabelProps[] }) {
  return (
    <Stepper alternativeLabel activeStep={0 || activeStep - 1} connector={<QontoConnector />}>
      {steps.map(({ label, ...props }, idx) => (
        <Step key={idx}>
          <StepLabel
            {...props}
            sx={{
              '& .MuiStepLabel-label': {
                marginTop: '8px',
              },
              '& .MuiTypography-caption': {
                position: 'relative',
                top: '-4px',
              },
            }}
            StepIconComponent={QontoStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
