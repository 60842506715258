// DeadlineCalendar.jsx

import React from 'react';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Deadlines } from 'models/booking.model';

export interface DeadlineDayProps {
  deadlineData: DeadlineData;
}

export interface DeadlineData extends Deadlines {
  etaPOL: string;
  etd: string;
  eta: string;
}

function DeadlineDay(props: PickersDayProps<any>, deadlineData: DeadlineData, color?: string) {
  const badgeContent: string[] = [];
  const formattedDate: string = props?.day?.format('YYYY-MM-DD') ?? '';
  Object.keys(deadlineData).forEach(key => {
    const deadlineDataValue = deadlineData[key as keyof DeadlineData];
    if (deadlineDataValue && deadlineDataValue.includes(formattedDate)) {
      switch (key) {
        case 'SI':
        case 'VGM':
        case 'UCR':
        case 'GateIn':
          badgeContent.push(key);
          break;
        case 'earliestRelease':
          badgeContent.push('Release');
          break;
        case 'earliestGateIn':
          badgeContent.push('1st GateIn');
          break;
        case 'etaPOL':
          badgeContent.push('ETA(POL)');
          break;
        case 'etd':
          badgeContent.push('ETD');
          break;
        case 'eta':
          badgeContent.push('ETA(POD)');
      }
    }
  });

  return (
    <Badge
      key={formattedDate}
      overlap='circular'
      badgeContent={badgeContent.join(', ') || null}
      sx={{
        color,
      }}
    >
      {/* todo: check this typing */}
      <PickersDay {...(props as PickersDayProps<any>)} day={props.day} />
    </Badge>
  );
}

const DeadlineCalendar: React.FC<DeadlineDayProps> = ({ deadlineData }) => {
  const theme = useTheme();
  const color = theme.palette.secondary[500];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
      <DateCalendar
        displayWeekNumber
        shouldDisableDate={(date: any) =>
          !Object.values(deadlineData).some(deadline => date.toISOString().includes(deadline))
        }
        slots={{
          day: props => DeadlineDay(props, deadlineData, theme.palette.secondary[400]),
        }}
        sx={{
          maxHeight: '20rem',
          width: '22rem',
          '& .MuiPickersDay-root,': {
            color,
          },
          '& .MuiTypography-root': {
            color,
          },
          '& .MuiPickersCalendarHeader-root': {
            color,
          },
          '& .MuiButtonBase-root': {
            color,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DeadlineCalendar;
