import { Box, Chip, useTheme } from '@mui/material';
import React from 'react';

export interface ChipTitleProps {
  title: string;
}

const ChipTitle: React.FC<ChipTitleProps> = ({ title }) => {
  const theme = useTheme();
  return (
    <Box>
      <Chip
        label={title}
        sx={{
          fontWeight: 'bold',
          color: theme.palette.secondary[100],
          bgcolor: theme.palette.secondary[300],
          textTransform: 'uppercase',
          letterSpacing: '0.1rem',
        }}
      />
    </Box>
  );
};

export default ChipTitle;
