interface GenericUser {
  _id: string;
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  email: string;
  creditPeriod: number;
  creditLimit: number;
  contactPerson?: string;
  phoneNumber?: string;
}

export interface Vendor extends GenericUser {
  vendorType: VendorType;
}

export interface Customer extends GenericUser {
  EORI?: string;
}

export interface Site {
  _id: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  siteName?: string;
}

export interface Token {
  _id: string;
  refreshToken: string;
  accessToken: string;
  tokenType?: string;
  expiresIn: number;
}

export interface Oauth {
  _id: string;
  provider: string;
  clientId: string;
  clientSecret: string;
  redirectUrl: string;
  scopes: string[];
  state?: string;
  authUrl: string;
  tokenUrl: string;
  tokens: Token[];
}

export interface Port {
  _id: string;
  portName: string;
  country: string;
  portCode: string;
}

export interface BaseDialogueProps {
  handleClose: Function;
  open: boolean;
}

export type ValueAndLabel<T> = {
  value: T;
  label: string;
};

export enum VendorType {
  Carrier = 'Carrier',
  Haulier = 'Haulier',
  Agent = 'Agent',
}
