import { Backdrop, Box, Fade, Modal, ModalProps, useTheme } from '@mui/material';
import React from 'react';

export type CustomModelProps = ModalProps;

const CustomModel: React.FC<CustomModelProps> = ({ open, onClose, children, ...modelProps }) => {
  const theme = useTheme();
  return (
    <Modal
      {...modelProps}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto', // Auto width to adjust based on content
            maxWidth: '100vw', // Maximum width of the modal to fit within the viewport
            bgcolor: theme.palette.background.default,
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '100vh', // Maximum height to avoid overflow
            overflowY: 'auto', // Scrollbar for overflow content
          }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModel;
