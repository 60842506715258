import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ArrowUpwardOutlined, ArrowDownwardOutlined } from '@mui/icons-material';
import { currencySymbols } from 'dataAssets/constants';

interface ProfitDisplayProps {
  expectedProfit: number | undefined;
  bookingCurrency: string;
  numberOfContainers: number;
}

const ProfitDisplay: React.FC<ProfitDisplayProps> = ({ expectedProfit, bookingCurrency, numberOfContainers }) => {
  const profitArrow =
    expectedProfit !== undefined ? (
      expectedProfit > 0 ? (
        <ArrowUpwardOutlined sx={{ color: 'green', marginLeft: '0.3rem' }} />
      ) : (
        <ArrowDownwardOutlined sx={{ color: 'red', marginLeft: '0.3rem' }} />
      )
    ) : null;

  return (
    <Box>
      <Typography
        sx={{
          margin: '1rem 0',
          padding: '0 1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {`Expected Profit: ${
          expectedProfit !== undefined
            ? `${currencySymbols[bookingCurrency]}${expectedProfit.toFixed(2)}`
            : 'Calculating...'
        }`}
        {profitArrow}
        {`( ${currencySymbols[bookingCurrency]}${
          expectedProfit !== undefined ? (expectedProfit / numberOfContainers).toFixed(2) : 'Calculating...'
        } / CN )`}
      </Typography>
    </Box>
  );
};

export default ProfitDisplay;
