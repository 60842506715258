import React from 'react';
import { useGetDocumentsQuery } from '../../state/api';
import { Box, Dialog, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import GeneralDialogueActions from 'components/GeneralDialogueActions';

export interface DocumentViewerProps {
  handleClose: Function;
  open: boolean;
  documentPath: string;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ handleClose, open, documentPath }) => {
  const { data, isLoading } = useGetDocumentsQuery(documentPath);

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {documentPath}
        </DialogTitle>
        <DialogContent>
          {data || !isLoading ? (
            <iframe title='document' src={`data:application/pdf;base64,${data}`} width='100%' height='100%'></iframe>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center' minHeight='75vh'>
              <CircularProgress size={'10rem'} />
            </Box>
          )}
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default DocumentViewer;
