import React, { cloneElement } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { BaseDialogueProps } from 'models/index.model';
import { Invoice } from 'models/booking.model';
import { invoiceTypes } from 'dataAssets/constants';
import GeneralDialogueActions from 'components/GeneralDialogueActions';

export interface InvoiceViewerProps extends BaseDialogueProps {
  invoice: Invoice;
  invoiceType: string;
  pdfView: React.JSX.Element;
}

const InvoiceViewer: React.FC<InvoiceViewerProps> = ({ handleClose, open, invoice, invoiceType, pdfView }) => {
  const clonedView = cloneElement(pdfView, {
    style: {
      height: '100%',
      width: '100%',
    },
  });
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`${invoiceTypes[invoiceType]}: ${invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber}`}
        </DialogTitle>
        <DialogContent>{clonedView}</DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default InvoiceViewer;
