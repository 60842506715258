import React, { useState } from 'react';
import { Booking } from 'models/booking.model';
import { Box, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { DirectionsBoatOutlined, EmojiPeopleOutlined, HailOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

export interface BookingDetailsProps {
  booking: Booking;
}

export interface BookingItem {
  label: string;
  value: string | number | undefined;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking }) => {
  const theme = useTheme();

  const [bookingDetailsType, setBookingDetailsType] = useState('shipment');

  const shipmentItems: BookingItem[] = [
    { label: 'FPOD:', value: booking.placeOfDelivery?.portCode },
    { label: 'Carrier:', value: booking.carrier?.name || '' },
    {
      label: 'Carrier Quotation No:',
      value: booking.carrierQuotationReference,
    },
    { label: 'Carrier Booking No:', value: booking.carrierBookingNumber },
    { label: 'MBL No:', value: booking.mblNumber },
    ...(booking.bookingType === 'Import' ? [{ label: 'HBL No:', value: booking.hblNumber || '' }] : []),
    { label: 'Agent:', value: booking.agent?.name || '' },
    { label: 'Cargo Description:', value: booking.cargoDescription },
    {
      label: 'No. of Containers:',
      value: `${booking.numberOfContainers} X ${booking.containerType}`,
    },
  ];

  const containerItems: BookingItem[] = [
    {
      label: 'Purchase Order No:',
      value: booking.purchaseOrderNumber || '',
    },
    ...(booking.bookingType === 'Import' ? [{ label: 'Release Pin:', value: booking.releasePin || '' }] : []),

    { label: 'Packages:', value: booking.packages },
    { label: 'Approximate Weight:', value: booking.approximateWeight },
    { label: 'Export Loading Type:', value: booking.loadingTypeExport },
    { label: 'Import Loading Type:', value: booking.loadingTypeImport },
    { label: 'Door Facing:', value: booking.doorFacing },
  ];

  const additionalItems: BookingItem[] = [
    { label: 'HS Code:', value: booking.HSCode },
    {
      label: 'Cargo Value:',
      value: booking.cargoValue?.value && `${booking.cargoValue?.currency} ${booking.cargoValue?.value}`,
    },
    { label: 'Notify Party:', value: booking.notifyParty?.name || '' },
    { label: 'MBL Status:', value: booking.mblStatus },
    { label: 'HBL Status:', value: booking.hblStatus },
    {
      label: 'Quotation Approval:',
      value: booking.quotationApproval ? 'Approved' : 'Pending',
    },
  ];

  const renderBookingDetails = (items: BookingItem[]) => {
    return items.map((item, index) => (
      <Box key={index}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>{item.label}</Box>
          <Box>{item.value || '-'}</Box>
        </Box>
        <Divider />
      </Box>
    ));
  };
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb='1rem'>
        <Typography
          variant='h2'
          fontWeight={'700'}
          sx={{
            color: theme.palette.secondary[500],
          }}
        >
          {booking.consignor?.name}
        </Typography>
        <HailOutlined
          sx={{
            color: theme.palette.secondary[400],
            fontSize: '3rem',
          }}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb='1rem'>
        <Typography variant='h2' fontWeight={'400'}>
          {booking.consignee?.name}
        </Typography>
        <EmojiPeopleOutlined
          sx={{
            color: theme.palette.secondary[400],
            fontSize: '3rem',
          }}
        />
      </Box>
      <Divider
        sx={{
          marginBottom: '1rem',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            color: theme.palette.secondary[300],
            textTransform: 'uppercase',
            letterSpacing: '0.1rem',
          }}
        >
          Shipment Details
        </Typography>
      </Divider>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'} padding={'0 1rem'}>
          <Typography variant='h5' sx={{ letterSpacing: '0.1rem' }}>
            {dayjs(booking.etd).format('DD MMM YYYY')}
          </Typography>
          <Typography variant='h5' sx={{ letterSpacing: '0.1rem' }}>
            {dayjs(booking.eta).format('DD MMM YYYY')}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} padding={'0 1rem'}>
          <Typography
            variant='h2'
            sx={{
              letterSpacing: '0.1rem',
              fontWeight: '600',
              color: theme.palette.secondary[400],
            }}
          >
            {booking.portOfLoading?.portCode}
          </Typography>
          <Box
            sx={{
              margin: 'auto',
              paddingTop: '5px',
              '& > *': {
                color: theme.palette.secondary[300],
              },
            }}
          >
            <DirectionsBoatOutlined
              sx={{
                width: '3.2rem',
                height: '3.2rem',
                bgcolor: theme.palette.secondary[300],
                color: theme.palette.secondary[400],
                padding: '5px',
                borderRadius: '50%',
              }}
            />
          </Box>
          <Typography
            variant='h2'
            sx={{
              letterSpacing: '0.1rem',
              fontWeight: '600',
              color: theme.palette.secondary[400],
            }}
          >
            {booking.portOfDestination?.portCode}
          </Typography>
        </Box>
        <Box textAlign={'center'} mt={'0.5rem'}>
          <Typography
            variant='h4'
            sx={{
              color: theme.palette.secondary[300],
              fontWeight: '500',
            }}
          >
            {`${booking.vesselVoyage.vesselName} ${booking.vesselVoyage.voyageNumber} [${booking.vesselVoyage.vesselFlag}]`}
          </Typography>
        </Box>
      </Box>
      <Box
        marginTop={'1rem'}
        border={`1px solid ${theme.palette.secondary[400]}`}
        borderRadius={'15px'}
        padding={'1rem'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'0.2rem'}>
          {bookingDetailsType === 'shipment' && renderBookingDetails(shipmentItems)}
          {bookingDetailsType === 'containers' && renderBookingDetails(containerItems)}
          {bookingDetailsType === 'additional' && renderBookingDetails(additionalItems)}
        </Box>
      </Box>
      <Box>
        <Tabs
          value={bookingDetailsType}
          onChange={(_, newValue) => setBookingDetailsType(newValue)}
          indicatorColor='secondary'
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.secondary[300],
            },
          }}
        >
          <Tab value='shipment' label='Shipment Details' />
          <Tab value='containers' label='Container Details' />
          <Tab value='additional' label='Additional Details' />
        </Tabs>
      </Box>
    </>
  );
};

export default BookingDetails;
