import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Box, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { TransportSchedule } from 'models/booking.model';

interface TransportScheduleTableProps {
  transportScheduleArray: TransportSchedule[];
  loadingTypeExport: 'SD' | 'CY';
}

const TransportScheduleTable: React.FC<TransportScheduleTableProps> = ({
  transportScheduleArray,
  loadingTypeExport,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Table
        sx={{
          '& .MuiTableCell-root': {
            padding: '5px',
            borderBottom: 'none',
            color: theme.palette.secondary[100],
          },
          '& .MuiTableHead-root .MuiTableCell-root': {
            fontWeight: '600',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {loadingTypeExport === 'SD' && (
              <>
                <TableCell>Site</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Reference</TableCell>
              </>
            )}
            <TableCell>Container Number</TableCell>
            <TableCell>Seal Number</TableCell>
            <TableCell>Weight (kg)</TableCell>
            <TableCell>Hauler</TableCell>
            <TableCell>Remarks</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transportScheduleArray.map((Schedule, index) => (
            <TableRow key={index}>
              {loadingTypeExport === 'SD' && (
                <>
                  <TableCell>{Schedule.site}</TableCell>
                  <TableCell>{Schedule.date ? dayjs(Schedule.date).format('DD-MM-YY') : 'N/A'}</TableCell>
                  <TableCell>{Schedule.date ? dayjs(Schedule.time).format('HH:mm') : 'N/A'}</TableCell>
                  <TableCell>{Schedule.reference}</TableCell>
                </>
              )}
              <TableCell>{Schedule.containerNumber}</TableCell>
              <TableCell>{Schedule.sealNumber}</TableCell>
              <TableCell>{Schedule.weight}</TableCell>
              <TableCell>{Schedule.hauler}</TableCell>
              <TableCell>{Schedule.remarks}</TableCell>
              <TableCell>{Schedule.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TransportScheduleTable;
