import { Button, DialogActions, useTheme } from '@mui/material';
import LoadingButton from 'components/LoadingButton';
import React from 'react';

export interface DialogueActionProps {
  onClick?: any;
  handleClose?: Function;
  submitText?: string;
  noSubmission?: boolean;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  pageNumber?: number;
  maxPages?: number;
}

const GeneralDialogueActions: React.FC<DialogueActionProps> = ({
  onClick = () => undefined,
  handleClose,
  submitText,
  noSubmission,
  setPageNumber,
  pageNumber = 1,
  maxPages = 1,
}) => {
  const theme = useTheme();
  return (
    <DialogActions>
      {pageNumber > 1 && (
        <Button
          onClick={() => setPageNumber && setPageNumber(pageNumber - 1)}
          sx={{
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[200],
            '&:hover': { bgcolor: theme.palette.secondary[300] },
          }}
        >
          Prev
        </Button>
      )}

      {!noSubmission && (
        <LoadingButton
          type={pageNumber === maxPages ? 'submit' : undefined}
          onClick={onClick}
          sx={{
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[200],
            '&:hover': { bgcolor: theme.palette.secondary[300] },
          }}
          text={pageNumber === maxPages ? (submitText ?? 'Submit') : 'Next'}
          loadingText='Loading...'
        />
      )}
      {!!handleClose && (
        <Button
          onClick={() => handleClose()}
          sx={{
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[100],
            '&:hover': { bgcolor: theme.palette.secondary[200] },
          }}
        >
          Cancel
        </Button>
      )}
    </DialogActions>
  );
};

export default GeneralDialogueActions;
