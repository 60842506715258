import { Alert, Box } from '@mui/material';
// import CloseIcon from "@mui/icons-material/Close";
import React from 'react';

export interface DeadlineAlertProps {
  deadlineType: string;
}
const DeadlineAlert: React.FC<DeadlineAlertProps> = ({
  // openNotifications,
  // setOpenNotifications,
  deadlineType,
}) => {
  return (
    <Box>
      {/* <Collapse in={openNotifications}> */}
      <Alert
        variant='filled'
        severity='warning'
        // action={
        //   <IconButton
        //     color="inherit"
        //     size="small"
        //     onClick={() => {
        //       setOpenNotifications(false);
        //     }}
        //   >
        //     <CloseIcon fontSize="inherit" />
        //   </IconButton>
        // }
        sx={{ mb: 2 }}
      >
        {deadlineType === 'Gate-In'
          ? `${deadlineType} Deadline is today`
          : `${deadlineType} Deadline is today - Please submit ${deadlineType} `}
      </Alert>
      {/* </Collapse> */}
    </Box>
  );
};

export default DeadlineAlert;
