import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const AuthenticationGuard: React.FC<{ component: React.FC<any> }> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <>
        <Box display='flex' alignItems='center' justifyContent='center' minHeight='75vh'>
          <CircularProgress size={'10rem'} />
        </Box>
      </>
    ),
  });

  return <Component />;
};
