export const additionalQuotationTermsAndConditions = [
  {
    section: 'Definitions',
    content: [
      `"Company": Refers to “Reudan International Limited” a freight forwarding company based in the UK and a member of the UK Road Hauliers Association (UKRHA).`,
      `"Customer": Refers to the entity requesting freight forwarding services.`,
      `"Cargo": The materials or goods being transported by the Company on behalf of the Customer.`,
      `"Services": Refers to all freight, forwarding, logistics, and related services provided by the Company.`,
      `"BIFA Terms": Refers to the British International Freight Association Standard Trading Conditions 2017 (latest edition), which apply to all services provided by the Company.`,
    ],
  },
  {
    section: 'Scope of Services',
    content: [
      `The Company agrees to provide freight forwarding services, including but not limited to the transportation, storage, customs clearance, and handling of cargo as requested by the Customer. The Company acts as an agent on behalf of the Customer and arranges for the transportation of cargo by third-party carriers, warehouses, and other subcontractors.`,
    ],
  },
  {
    section: 'Application of BIFA Terms and UKRHA Standards',
    content: [
      `All services provided by the Company are subject to the BIFA Standard Trading Conditions (2017 edition), a copy of which is available on request.`,
      `The BIFA Terms apply in conjunction with these Terms and Conditions, and where any conflict arises, the BIFA Terms will take precedence.`,
      `As a member of the Road Hauliers Association (RHA), the Company adheres to the industry standards and best practices set forth by the RHA.`,
    ],
  },
  {
    section: 'Quotations',
    content: [
      `All quotations provided by the Company are based on the information supplied by the Customer.`,
      `Quotations are subject to revision if there are any changes in the weight, volume, classification of the cargo, or additional services required.`,
      `Prices are exclusive of VAT and other applicable taxes unless otherwise stated.`,
      `Any surcharges or additional costs not specified in the quotation, such as demurrage, customs duties, or taxes, are the responsibility of the Customer.`,
    ],
  },
  {
    section: 'Liability & Limitations',
    content: [
      `The Company shall not be liable for any loss, damage, or delay to the cargo unless caused by the Company's proven negligence. In such cases, the Company’s liability will be limited as per the BIFA Terms, which cap liability at 2 SDR per kg of the gross weight of the goods lost or damaged, or as otherwise specified.`,
      `The Company shall not be liable for delays, non-performance, or any loss caused by force majeure events, including but not limited to war, natural disasters, strikes, or governmental restrictions.`,
      `The Customer is responsible for arranging appropriate cargo insurance. The Company will not insure the goods unless requested in writing.`,
    ],
  },
  {
    section: 'Payment Terms',
    content: [
      `Payment Terms: Cash against documents unless credit terms have been previously approved in writing.`,
      `Interest will be charged at 8% per annum on overdue amounts.`,
      `The Company reserves the right to withhold services or withhold delivery of cargo in the event of overdue payments.`,
    ],
  },
  {
    section: 'Customer’s Obligations',
    content: [
      `The Customer must provide accurate and complete information regarding the nature, weight, volume, and classification of the cargo.`,
      `The Customer is responsible for ensuring that the cargo is properly packed, marked, and labelled in compliance with applicable regulations and industry standards.`,
      `The Customer must comply with all relevant laws, regulations, and export/import controls in the country of origin and destination.`,
      `The Customer shall indemnify the Company against any claims, fines, or damages resulting from misdeclaration of cargo or non-compliance with regulations.`,
    ],
  },
  {
    section: 'Customs and Documentation',
    content: [
      `The Company acts as an agent on behalf of the Customer for customs clearance. The Customer is responsible for providing all necessary documentation, such as invoices, certificates, and permits.`,
      `The Company shall not be liable for delays, penalties, or additional charges resulting from incomplete or inaccurate documentation provided by the Customer.`,
    ],
  },
  {
    section: 'Insurance',
    content: [
      `Unless specifically agreed in writing, the Company does not provide cargo insurance.`,
      `The Customer is responsible for insuring the cargo against risks such as damage, loss, or theft.`,
      `If requested, the Company can arrange insurance on behalf of the Customer for an additional charge.`,
    ],
  },
  {
    section: 'Dangerous Goods and Prohibited Items',
    content: [
      `The Company does not handle prohibited or dangerous goods unless a prior agreement has been made in writing and full disclosure of the cargo’s nature has been provided by the Customer.`,
      `The Customer is responsible for ensuring that any hazardous goods comply with all relevant safety regulations and requirements.`,
      `The Company reserves the right to refuse any shipment that contains illegal or improperly declared hazardous goods.`,
    ],
  },
  {
    section: 'Termination',
    content: [
      `The Company may terminate the agreement immediately in the event of a breach by the Customer or if the Customer becomes insolvent.`,
      `Upon termination, any outstanding payments for services rendered must be settled immediately by the Customer.`,
    ],
  },
  {
    section: 'Force Majeure',
    content: [
      `Neither party shall be liable for any failure to perform its obligations due to a force majeure event, which includes but is not limited to natural disasters, war, government actions, and other unforeseeable circumstances outside of the reasonable control of the affected party.`,
    ],
  },
  {
    section: 'Governing Law and Jurisdiction',
    content: [
      `(A) These conditions and any act or contract to which they apply shall be governed by English law.`,
      `(B) Any dispute arising out of any act or contract to which these Conditions apply shall, save as provided in (C) below, be subject to the exclusive jurisdiction of the English courts.`,
      `(C) Notwithstanding (B) above, the Company is entitled to require any dispute to be determined by arbitration.`,
      `(D) The Company may exercise its rights under (C) above either by itself commencing arbitration in respect of a dispute or by giving written notice to the Customer requiring a dispute to be determined by arbitration.`,
      `(E) In the event that the Company exercises its rights under (C) above, the corresponding arbitration shall be conducted as follows:`,
      `(i) Where the amount claimed by the claimant is less than £400,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a tribunal of three arbitrators and the arbitration shall be conducted in accordance with the LMAA Intermediate Claims Procedure applicable at the date of the commencement of the arbitration proceedings;`,
      `(ii) Where the amount claimed by the claimant is less than £100,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a sole arbitrator and the arbitration shall be conducted in accordance with the LMAA Small Claims Procedure applicable at the date of the commencement of the arbitration proceedings;`,
      `(iii) In any case where neither of the LMAA Procedures referred to in (i) and/or (ii) above applies, the reference shall be to three arbitrators in accordance with the LMAA Terms applicable at the date of the commencement of the arbitration proceedings.`,
    ],
  },
  {
    section: 'Severability',
    content: [
      `If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.`,
    ],
  },
  {
    section: 'Entire Agreement',
    content: [
      `These Terms and Conditions, together with the BIFA Standard Trading Conditions and any relevant quotation or service agreement, constitute the entire agreement between the Company and the Customer and supersede any prior agreements or understandings.`,
    ],
  },
  {
    section: 'Amendments',
    content: [
      `The Company reserves the right to amend these Terms and Conditions at any time. Any amendments will be communicated in writing to the Customer and will apply to all future services provided after the notice of such changes.`,
    ],
  },
  {
    section: 'Acceptance of Terms',
    content: [
      `These Terms and Conditions will apply to all services provided by the Company unless the Customer provides written notice to the Company of any objections or alternative terms within 24 Hours from receipt of these Terms and Conditions. If no written objections are received within this period, these Terms and Conditions shall be deemed accepted by the Customer.`,
    ],
  },
];
