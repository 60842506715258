import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikRegular from '../../assets/fonts/Rubik-Regular.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikMedium from '../../assets/fonts/Rubik-Medium.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikSemiBold from '../../assets/fonts/Rubik-SemiBold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikBold from '../../assets/fonts/Rubik-Bold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import ReudanLogo from '../../assets/REUDAN LOGO.png';
import { Booking, StepStatus, TransportSchedule } from 'models/booking.model';
import { extractDeadlineTime, formatDeadline } from 'utils/utils';
import { reudanAddress } from 'dataAssets/constants';
import { exportSteps } from 'dataAssets/actionSteps';

Font.register({
  family: 'Rubik',
  fonts: [
    { src: FontRubikRegular },
    { src: FontRubikMedium, fontWeight: 'medium' },
    { src: FontRubikSemiBold, fontWeight: 'semibold' },
    { src: FontRubikBold, fontWeight: 'bold' },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Rubik',
    fontSize: 9,
  },
  sectionHeading: {
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginVertical: 10,
    fontWeight: 'semibold',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginBottom: 8,
  },
  dataCell: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bookingCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  quotationCell: {
    width: '25%',
    textAlign: 'center',
  },
  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-160%, -50%)',
    opacity: 0.2,
    fontSize: 120,
    fontWeight: 'bold',
    color: 'gray',
    pointerEvents: 'none',
  },
  watermarkText: {
    transform: 'rotate(-45deg)',
  },
});

export interface TransportScheduleDocumentProps {
  booking: Booking;
  transportSchedule: TransportSchedule[];
}
const TransportScheduleDocument: React.FC<TransportScheduleDocumentProps> = ({ booking, transportSchedule }) => {
  const filteredLoadSites = booking.transportSites?.filter(transportSite =>
    transportSchedule?.some(schedule => schedule.site === transportSite.site?.city),
  );
  const isCarrierBookingCompleted = booking.stepsCompleted.some(
    step => step.dbRef === exportSteps[0].dbRef && step.status === StepStatus.Completed,
  );
  return (
    <Document>
      <Page size='A4' style={styles.body}>
        {(!isCarrierBookingCompleted || !booking.carrierBookingNumber) && (
          <View style={styles.watermark}>
            <Text style={styles.watermarkText}>DRAFT</Text>
          </View>
        )}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              Reudan International Ltd
            </Text>
            <Text
              style={{
                fontSize: 8,
              }}
            >
              {reudanAddress}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 'semibold',
                marginTop: 20,
              }}
            >
              {`Transport Schedule: ${booking.bookingNumber}`}
            </Text>
          </View>
          <View
            style={{
              width: '15%',
            }}
          >
            <Image src={ReudanLogo} />
          </View>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
          }}
        >
          <Text style={styles.sectionHeading}>Shipment Details</Text>
          <View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Carrier:'}</Text>
                <Text
                  style={{
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >
                  {booking.carrier?.name}
                </Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{`Port of ${booking.bookingType === 'Export' ? 'Loading' : 'Destination'}`}</Text>
                <Text>
                  {booking.bookingType === 'Export'
                    ? booking.portOfLoading?.portName
                    : booking.portOfDestination?.portName}
                </Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Carrier Booking No:'}</Text>
                <Text>{booking.carrierBookingNumber}</Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'Cargo Details:'}</Text>
                <Text
                  style={{
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >
                  {booking.cargoDescription}
                </Text>
              </View>
            </View>
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'No. of Containers:'}</Text>
                <Text>{`${transportSchedule?.length} x ${booking.containerType}`}</Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{'Approximate Weight:'}</Text>
                <Text>{`${booking.approximateWeight} kg`}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              paddingTop: 10,
              borderTop: '0.5px solid black',
            }}
          >
            <View style={styles.dataRow}>
              <View style={styles.dataCell}>
                <Text>{'Vessel V. Voyage:'}</Text>
                <Text
                  style={{
                    width: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    textAlign: 'right',
                  }}
                >{`${booking.vesselVoyage.vesselName} ${booking.vesselVoyage.voyageNumber}`}</Text>
              </View>
              <View style={styles.dataCell}>
                <Text>{`${booking.bookingType === 'Export' ? 'ETD' : 'ETA'}`}</Text>
                <Text>
                  {booking.bookingType === 'Export'
                    ? dayjs(booking.etd).format('DD-MM-YYYY')
                    : dayjs(booking.eta).format('DD-MM-YYYY')}
                </Text>
              </View>
            </View>
            {booking.bookingType === 'Export' && (
              <>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{'Empty Release Date:'}</Text>
                    <Text>{`${formatDeadline(booking.deadlines?.earliestRelease)} @ ${extractDeadlineTime(
                      booking.deadlines?.earliestRelease,
                    )}`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{'1st Free day to Gate In:'}</Text>
                    <Text>{`${formatDeadline(booking.deadlines?.earliestGateIn)} @ ${extractDeadlineTime(
                      booking.deadlines?.earliestGateIn,
                    )}`}</Text>
                  </View>
                </View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{'Gate In Cut Off:'}</Text>
                    <Text>{`${formatDeadline(booking.deadlines?.GateIn)} @ ${extractDeadlineTime(
                      booking.deadlines?.GateIn,
                    )}`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{'VGM Cut Off:'}</Text>
                    <Text>{`${formatDeadline(
                      booking.deadlines?.VGM,
                    )} @ ${extractDeadlineTime(booking.deadlines?.VGM)}`}</Text>
                  </View>
                </View>
                <View style={styles.dataRow}>
                  <View style={styles.dataCell}>
                    <Text>{'SI Cut Off:'}</Text>
                    <Text>{`${formatDeadline(
                      booking.deadlines?.SI,
                    )} @ ${extractDeadlineTime(booking.deadlines?.SI)}`}</Text>
                  </View>
                  <View style={styles.dataCell}>
                    <Text>{'UCR Cut Off:'}</Text>
                    <Text>{`${formatDeadline(
                      booking.deadlines?.UCR,
                    )} @ ${extractDeadlineTime(booking.deadlines?.UCR)}`}</Text>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
        <View
          style={{
            borderTop: '1px solid black',
          }}
        >
          <Text style={styles.sectionHeading}>{`${
            booking.bookingType === 'Export' ? 'Loading' : 'Delivery'
          } Details`}</Text>
        </View>
        {booking.loadingTypeExport === 'SD' && (
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
                marginTop: 5,
                marginBottom: 20,
              }}
            >
              {filteredLoadSites?.map((transportSite, index) => {
                const site = transportSite.site;
                return (
                  <View
                    key={index}
                    style={{
                      width: '20%',
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 'medium',
                        marginBottom: 4,
                      }}
                    >{`${booking.bookingType === 'Export' ? 'Loading' : 'Delivery'} Point ${index + 1}:`}</Text>
                    <Text
                      style={{
                        lineHeight: 1.4,
                      }}
                    >
                      {`${site?.siteName}, ${site?.address}, ${site?.city}, ${site?.postcode}`}
                    </Text>
                  </View>
                );
              })}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 10,
                borderBottom: '0.5px solid black',
              }}
            >
              <View
                style={{
                  width: '25%',
                  textAlign: 'center',
                }}
              >
                <Text>Site</Text>
              </View>
              <View
                style={{
                  width: '10%',
                  textAlign: 'center',
                }}
              >
                <Text>Date</Text>
              </View>
              <View
                style={{
                  width: '10%',
                  textAlign: 'center',
                }}
              >
                <Text>Time</Text>
              </View>
              <View
                style={{
                  width: '25%',
                  textAlign: 'center',
                }}
              >
                <Text>Reference</Text>
              </View>
              {booking.bookingType === 'Import' && (
                <>
                  <View
                    style={{
                      width: '15%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Container No</Text>
                  </View>
                  <View
                    style={{
                      width: '15%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Seal No</Text>
                  </View>
                  <View
                    style={{
                      width: '15%',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Weight</Text>
                  </View>
                </>
              )}
            </View>
            {transportSchedule?.map((load, index) => (
              <View
                key={`load-${index}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: 10,
                }}
              >
                <View
                  style={{
                    width: '25%',
                    textAlign: 'center',
                  }}
                >
                  <Text>{load.site}</Text>
                </View>
                <View
                  style={{
                    width: '10%',
                    textAlign: 'center',
                  }}
                >
                  <Text>{dayjs(load.date).format('DD-MM-YYYY')}</Text>
                </View>
                <View
                  style={{
                    width: '10%',
                    textAlign: 'center',
                  }}
                >
                  <Text>{dayjs(load.time).format('HH:mm')}</Text>
                </View>
                <View
                  style={{
                    width: '25%',
                    textAlign: 'center',
                  }}
                >
                  <Text>{load.reference}</Text>
                </View>
                {booking.bookingType === 'Import' && (
                  <>
                    <View
                      style={{
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{load.containerNumber}</Text>
                    </View>
                    <View
                      style={{
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{load.sealNumber}</Text>
                    </View>
                    <View
                      style={{
                        width: '15%',
                        textAlign: 'center',
                      }}
                    >
                      <Text>{load.weight}</Text>
                    </View>
                  </>
                )}
              </View>
            ))}
          </View>
        )}

        <View
          style={{
            marginTop: 20,
            paddingTop: 10,
          }}
        >
          <View style={{ marginBottom: 10 }}>
            {booking.pickupDepot?.map((depot, index) => (
              <View key={`pickup-depot-${index}`}>
                <Text style={{ fontWeight: 'medium', marginBottom: 4 }}>{`Pickup Depot ${index + 1} :`}</Text>
                {booking.pickupDepot ? (
                  <>
                    <Text>
                      {`Address: ${depot.site?.siteName}, ${depot.site?.address}, ${depot.site?.city}, ${depot.site?.postcode} `}
                    </Text>
                    <Text>{`Reference: ${depot.reference ?? 'N/A'}`}</Text>
                  </>
                ) : (
                  <Text>{`No Pickup Depot`}</Text>
                )}
              </View>
            ))}
          </View>
          <View style={{ marginBottom: 10 }}>
            {booking.dropoffDepot?.map((depot, index) => (
              <View key={`dropoff-depot-${index}`}>
                <Text style={{ fontWeight: 'medium', marginBottom: 4 }}>{`Dropoff Depot ${index + 1} :`}</Text>
                {booking.pickupDepot ? (
                  <>
                    <Text>
                      {`Address: ${depot.site?.siteName}, ${depot.site?.address}, ${depot.site?.city}, ${depot.site?.postcode} `}
                    </Text>
                    <Text>{`Reference: ${depot.reference ?? 'N/A'}`}</Text>
                  </>
                ) : (
                  <Text>{`No Dropoff Depot`}</Text>
                )}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TransportScheduleDocument;
