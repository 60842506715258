import { Box, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './scenes/layout';
import Bookings from './scenes/bookings';
import Booking from './scenes/booking';
import CreateBooking from './scenes/createBooking';
import Customers from './scenes/customers';
import Vendors from './scenes/vendors';
import Deadlines from './scenes/deadlines';
import Loadings from './scenes/loadings';
import HomePage from './scenes/homePage';
import AuthRedirect from './scenes/authRedirect';
import Sites from './scenes/sites';
import Oauth from './scenes/oauth';
import Invoices from './scenes/invoices';
import Ports from './scenes/ports';
import PrivacyPolicy from './scenes/privacyPolicy';
import TermsAndConditions from './scenes/termsAndConditions';
import { AuthenticationGuard } from './auth/AuthenticationGuard';
import { sec } from './auth/accessToken';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const mode = useSelector(state => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const { isLoading, getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return (
      <>
        <Box display='flex' alignItems='center' justifyContent='center' minHeight='75vh'>
          <CircularProgress size={'10rem'} />
        </Box>
      </>
    );
  }

  return (
    <div className='app'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Navigate to='/homepage' replace />} />
            <Route path='/homepage' element={<HomePage />} />
            <Route path='/bookings' element={<AuthenticationGuard component={Bookings} />} />
            <Route path='/bookings/:id' element={<AuthenticationGuard component={Booking} />} />
            <Route path='/bookings/create' element={<AuthenticationGuard component={CreateBooking} />} />
            <Route path='/customers' element={<AuthenticationGuard component={Customers} />} />
            <Route path='/vendors' element={<AuthenticationGuard component={Vendors} />} />
            <Route path='/deadlines' element={<AuthenticationGuard component={Deadlines} />} />
            <Route path='/loadings' element={<AuthenticationGuard component={Loadings} />} />
            <Route path='/sites' element={<AuthenticationGuard component={Sites} />} />
            <Route path='/invoices' element={<AuthenticationGuard component={Invoices} />} />
            <Route path='/ports' element={<AuthenticationGuard component={Ports} />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
            <Route path='/oauth' element={<AuthenticationGuard component={Oauth} />} />
          </Route>
          <Route path='/auth-redirect' element={<AuthenticationGuard component={AuthRedirect} />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
