import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import BookingConfirmation from '../BookingConfirmation';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';

export interface BookingConfirmationViewerProps extends BaseDialogueProps {
  booking: any;
}

const BookingConfirmationViewer: React.FC<BookingConfirmationViewerProps> = ({ handleClose, open, booking }) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`Booking Confirmation: ${booking.bookingNumber}`}
        </DialogTitle>
        <DialogContent>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <BookingConfirmation booking={booking} />
          </PDFViewer>
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default BookingConfirmationViewer;
