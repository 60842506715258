import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import LoadingButton from 'components/LoadingButton';

type Props = {
  steps: any[];
  activeStep: number;
  onClick?: () => void;
  onClickBack?: () => void;
  loading?: boolean;
};

function ButtonStepper({ steps, activeStep, onClick, onClickBack, loading }: Props) {
  const theme = useTheme();
  const isLastStep = activeStep === steps.length;

  return (
    <Container maxWidth='xs' sx={{ position: 'fixed', left: '0', bottom: '0', right: '0', p: 0 }}>
      <Box
        sx={{
          p: 2,
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {activeStep > 1 && (
          <Button
            type='button'
            color='primary'
            variant='outlined'
            disabled={loading}
            fullWidth
            onClick={onClickBack}
            sx={{
              color: theme.palette.primary[700],
              bgcolor: theme.palette.secondary[100],
              '&:hover': { bgcolor: theme.palette.secondary[200] },
            }}
          >
            Back
          </Button>
        )}
        <LoadingButton
          type='submit'
          color='primary'
          variant='contained'
          fullWidth
          onClick={onClick}
          loadingText='Loading...'
          text={isLastStep ? 'Submit' : 'Next'}
          disabled={loading}
          sx={{
            color: theme.palette.primary[700],
            bgcolor: theme.palette.secondary[200],
            '&:hover': { bgcolor: theme.palette.secondary[300] },
          }}
        />
      </Box>
    </Container>
  );
}

export default ButtonStepper;
