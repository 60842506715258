import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, generatePath } from 'react-router-dom';
import { sec } from '../../auth/accessToken';
import { Booking } from 'models/booking.model';
import BookingDialogueContent from 'components/BookingDialogueContent';
import AlertSnackbar from 'components/AlertSnackbar';
import { AlertColor } from '@mui/material/Alert';
import { setDefaultBookingValues, useUserPermissions, handleSnackbarClose } from 'utils/utils';

const CreateBooking = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('create:bookings-export');
  const hasImportPermission = userPermissions.includes('create:bookings-import');

  const useFormReference = useForm<Booking>({
    defaultValues: setDefaultBookingValues(hasExportPermission, hasImportPermission),
  });
  const { handleSubmit } = useFormReference;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const onSubmit = async (data: Booking) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      navigate(
        generatePath(`/bookings/${response.data.id}`, {
          id: response.data.id,
        }),
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        const errorMessage = error.response?.data?.message || 'An error occurred';
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('An unexpected error occurred');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  return (
    <Box m='1.5rem 2.5rem'>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <BookingDialogueContent
          useFormReference={useFormReference}
          hasExportPermission={hasExportPermission}
          hasImportPermission={hasImportPermission}
        />
        <Box>
          <Button
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit)}
            sx={{
              bgcolor: theme.palette.secondary[500],
              marginLeft: '1rem',
              mb: '1rem',
              '&:hover': {
                backgroundColor: theme.palette.secondary[600],
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default CreateBooking;
