import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { JSONTree } from 'react-json-tree';
import GeneralDialogueActions from '../GeneralDialogueActions';
import { AuditLog } from '../../models/booking.model';
import { BaseDialogueProps } from '../../models/index.model';

interface AuditLogsDialogueProps extends BaseDialogueProps {
  auditLogs: AuditLog[] | undefined;
}

const AuditLogsDialogue: React.FC<AuditLogsDialogueProps> = ({ handleClose, open, auditLogs }) => {
  const theme = useTheme();

  const [selectedLogDetails, setSelectedLogDetails] = useState<any | null>(null);
  const handleDetailsClick = (details: any) => {
    setSelectedLogDetails(details);
  };
  const handleDetailsClose = () => {
    setSelectedLogDetails(null);
  };

  const columns: GridColDef[] = [
    { field: 'action', headerName: 'Actions', flex: 0.3 },
    { field: 'actor', headerName: 'Actor', flex: 0.3 },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 0.3,
      valueFormatter: params => {
        return new Date(params.value as string).toLocaleString();
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.1,
      renderCell: params =>
        params.row.details && (
          <IconButton
            sx={{
              color: theme.palette.secondary[400],
            }}
            onClick={() => handleDetailsClick(params.row.details)}
          >
            <InfoOutlined />
          </IconButton>
        ),
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Audit Logs
        </DialogTitle>
        <DialogContent>
          <Box height={'70vh'}>
            <DataGrid
              rows={auditLogs || []}
              getRowId={row => row._id}
              columns={columns}
              density='compact'
              sortModel={[
                {
                  field: 'timestamp',
                  sort: 'desc',
                },
              ]}
            />
          </Box>
        </DialogContent>
        <GeneralDialogueActions noSubmission handleClose={handleClose} />
      </Dialog>

      <Dialog open={!!selectedLogDetails} onClose={handleDetailsClose} fullWidth maxWidth='md'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Changes
        </DialogTitle>
        <DialogContent>
          <JSONTree data={selectedLogDetails || {}} shouldExpandNodeInitially={() => true} hideRoot />
        </DialogContent>
        <GeneralDialogueActions noSubmission handleClose={handleDetailsClose} />
      </Dialog>
    </Box>
  );
};

export default AuditLogsDialogue;
