import React, { useState } from 'react';
import { Button, ButtonProps } from '@mui/material';

export interface LoadingButtonProps extends ButtonProps {
  loadingText: string;
  text: string;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ onClick, text, loadingText, disabled, ...props }) => {
  const [loading, setLoading] = useState(disabled);
  if (!disabled && typeof onClick === 'function') {
    const actualOnClickFn = onClick;
    onClick = async e => {
      setLoading(true);
      try {
        await actualOnClickFn(e);
      } catch (ex) {
        console.error(ex);
      }
      setLoading(false);
    };
  }

  return (
    <Button disabled={disabled || loading} onClick={onClick} {...props}>
      {disabled || loading ? loadingText : text}
    </Button>
  );
};

export default LoadingButton;
