import React, { useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps } from 'models/index.model';
import { Booking, Deadlines } from 'models/booking.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { useGetAuditsQuery } from 'state/api';

export interface DeadlinesDialogueProps extends BaseDialogueProps {
  id: string;
  etaPOL: string;
  deadlines: Deadlines;
}

const DeadlinesDialogue: React.FC<DeadlinesDialogueProps> = ({ handleClose, open, id, etaPOL, deadlines }) => {
  const hasDeadlines = !Object.values(deadlines).some(deadline => deadline === 'N/A');

  const etaPOLDate = dayjs(etaPOL);
  const earliestDeadlineDate = dayjs(etaPOLDate).subtract(14, 'day');
  const currentDate = dayjs();

  const defaultDate = (earliestDeadlineDate.isAfter(currentDate) ? earliestDeadlineDate : currentDate).format(
    'YYYY-MM-DD',
  );
  const setDefaults = (test: Deadlines, defaultValue?: string) =>
    Object.entries(test).reduce((acc: Record<string, string>, [deadlineType, value]) => {
      acc[deadlineType] = value === 'N/A' ? (defaultValue ?? defaultDate) : value;
      return acc;
    }, {});

  const currentDeadlines = setDefaults(deadlines);

  const { handleSubmit, control } = useForm<Booking>({
    defaultValues: {
      deadlines: currentDeadlines,
    },
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', id]);

  const onSubmit = async (data: Booking) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      handleClose();
      setSnackbarOpen(true);
      auditLogsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`${hasDeadlines ? 'Update' : 'Add'} Deadlines`}
        </DialogTitle>
        <DialogContent>
          <form>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box display={'grid'} gridTemplateColumns={'repeat(6,1fr)'} mt={'1rem'} gap={'1rem'}>
                <Controller
                  name={'deadlines.SI'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        format='DD/MM/YYYY'
                        ampm={false}
                        label='SI Deadline'
                        value={dayjs(currentDeadlines.SI)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name={'deadlines.VGM'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        label='VGM Deadline'
                        format='DD/MM/YYYY'
                        ampm={false}
                        value={dayjs(currentDeadlines.VGM)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name={'deadlines.UCR'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        label='UCR Deadline'
                        format='DD/MM/YYYY'
                        ampm={false}
                        value={dayjs(currentDeadlines.UCR)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name={'deadlines.earliestRelease'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        label='Earliest Release'
                        format='DD/MM/YYYY'
                        ampm={false}
                        value={dayjs(currentDeadlines.earliestRelease)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name={'deadlines.earliestGateIn'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        label='Earliest Gate In'
                        format='DD/MM/YYYY'
                        ampm={false}
                        value={dayjs(currentDeadlines.earliestGateIn)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
                <Controller
                  name={'deadlines.GateIn'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box>
                      <DateTimePicker
                        label='Gate In Deadline'
                        format='DD/MM/YYYY'
                        ampm={false}
                        value={dayjs(currentDeadlines.GateIn)}
                        onChange={date => onChange(dayjs(date).format())}
                        minDate={dayjs(etaPOL).subtract(14, 'day')}
                        maxDate={dayjs(etaPOL).subtract(1, 'day')}
                      />
                    </Box>
                  )}
                />
              </Box>
            </LocalizationProvider>
          </form>
        </DialogContent>
        <GeneralDialogueActions
          onClick={handleSubmit(onSubmit)}
          handleClose={handleClose}
          submitText={hasDeadlines ? 'Update' : 'Create'}
        />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={'Deadlines Updated Successfully !'}
      />
    </Box>
  );
};

export default DeadlinesDialogue;
