import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { generateToken, useInterval } from 'utils/utils';
import { AxiosResponse } from 'axios';
// @ts-expect-error: TODO: Fix typing issue
import successSVG from '../../assets/svg/check.svg';
// @ts-expect-error: TODO: Fix typing issue
import errorSVG from '../../assets/svg/exclamation-mark.svg';

const AuthRedirect = () => {
  const [searchParams] = useSearchParams();

  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const gen = async () => {
      try {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        if (code === null || state === null) {
          throw new Error('Not a proper state');
        }
        const res: AxiosResponse = await generateToken(state, code);
        console.log('response from server is ', res.data);
        if (res.status === 200) {
          setValid(true);
        }
      } catch (e) {
        console.log(e);
        setValid(false);
      }
      setLoading(false);
    };
    gen();
  }, [searchParams]);

  useInterval(() => {
    if (loading === true) {
      return;
    }
    const current = counter - 1;
    if (current === 0) {
      window.close();
    } else {
      setCounter(current);
    }
  }, 1000);

  return (
    <>
      <div className='h-[100vh] w-full flex flex-col items-center overflow-hidden rounded-md'>
        <h1 className='mt-10 md:text-7xl text-3xl lg:text-9xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 relative z-20'>
          Candor
        </h1>
        <div className='w-[40rem] h-20 relative'>
          <div className='absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm' />
          <div className='absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4' />
          <div className='absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm' />
          <div className='absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4' />
        </div>
        {loading ? null : (
          <Card
            elevation={0}
            className='bg-clip-text flex flex-col items-center content-center text-center overflow-hidden'
          >
            <CardMedia component='svg' sx={{ height: 140, width: 140 }} image={valid ? successSVG : errorSVG} />
            <CardContent>
              <Typography gutterBottom variant='h4' component='div'>
                Token Generation {valid ? 'Successful' : 'Failed'}
              </Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                This window will automatically close in {counter} seconds
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant='outlined' size='large' onClick={() => window.close()}>
                Close
              </Button>
            </CardActions>
          </Card>
        )}
      </div>

      {loading ? (
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : null}
    </>
  );
};

export default AuthRedirect;
