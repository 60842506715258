import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Booking, Invoice } from 'models/booking.model';
import { currencySymbols } from 'dataAssets/constants';
import { extractInvoiceType, totalInvoiceValue } from 'utils/utils';

export interface InvoiceReportTableProps {
  invoices: Invoice[];
  booking: Booking;
  currentBookingIncome: Booking['bookingIncome'];
}

const InvoiceReportTable: React.FC<InvoiceReportTableProps> = ({ invoices, booking, currentBookingIncome }) => {
  const renderInvoiceRow = (invoice: Invoice) => (
    <TableRow key={invoice._id}>
      <TableCell>{invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber}</TableCell>
      <TableCell>{invoice.vendorSnapshot?.name ?? invoice.customerSnapshot?.name ?? 'N/A'}</TableCell>
      <TableCell>{invoice.vendorInvoiceNumber ?? 'N/A'}</TableCell>
      <TableCell>{dayjs(invoice.invoiceDate).format('DD/MM/YY')}</TableCell>
      <TableCell>
        {extractInvoiceType(invoice.performaInvoiceNumber) === 'PI'
          ? dayjs(invoice.vendorInvoiceDate).format('DD/MM/YY')
          : invoice.approvalDate
            ? dayjs(invoice.approvalDate).format('DD/MM/YY')
            : 'TBD'}
      </TableCell>
      <TableCell>
        {['DN', 'CN'].some(invoiceType => invoice.performaInvoiceNumber.includes(invoiceType))
          ? 'N/A'
          : invoice.vendorInvoiceDate
            ? dayjs(invoice.vendorInvoiceDate)
                .add(invoice.vendorSnapshot?.creditPeriod ?? 0, 'day')
                .format('DD/MM/YY')
            : invoice.approvalDate
              ? dayjs(invoice.approvalDate)
                  .add(invoice.customerSnapshot?.creditPeriod ?? invoice.vendorSnapshot?.creditPeriod ?? 0, 'day')
                  .format('DD/MM/YY')
              : 'TBD'}
      </TableCell>
      <TableCell>{invoice.disputeDetails?.underDispute ? 'Yes' : 'No'}</TableCell>
      <TableCell>{invoice.approvalPending ? 'No' : 'Yes'}</TableCell>
      <TableCell>{invoice.invoiceOutstanding ? 'No' : 'Yes'}</TableCell>
      <TableCell>
        {`${currencySymbols[invoice.invoiceCurrency]} ${totalInvoiceValue(
          invoice.charges,
          invoice.numberOfContainers,
        )}`}
      </TableCell>
      <TableCell>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Charges</AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Charge Name</TableCell>
                    <TableCell>Currency</TableCell>
                    <TableCell>Ex. Rate</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Base</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.charges.map(charge => (
                    <TableRow key={charge._id}>
                      <TableCell>{charge.chargeName}</TableCell>
                      <TableCell>{charge.currency}</TableCell>
                      <TableCell>{charge.exchangeRate}</TableCell>
                      <TableCell>{charge.rate}</TableCell>
                      <TableCell>{charge.base}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );

  const revenueInvoices = invoices.filter(
    invoice =>
      extractInvoiceType(invoice.performaInvoiceNumber) === 'SI' ||
      extractInvoiceType(invoice.performaInvoiceNumber) === 'DN',
  );

  const costInvoices = invoices.filter(
    invoice =>
      extractInvoiceType(invoice.performaInvoiceNumber) === 'PI' ||
      extractInvoiceType(invoice.performaInvoiceNumber) === 'CN',
  );

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>Invoice Party</TableCell>
              <TableCell>PI No</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Dispute</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Settled</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Charges</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {revenueInvoices.map(invoice => renderInvoiceRow(invoice))}
            <TableRow>
              <TableCell colSpan={9}>Revenue</TableCell>
              <TableCell colSpan={2}>{`£${booking.bookingIncome?.revenue ?? currentBookingIncome?.revenue}`}</TableCell>
            </TableRow>
            {costInvoices.map(invoice => renderInvoiceRow(invoice))}
            <TableRow>
              <TableCell colSpan={9}>Cost</TableCell>
              <TableCell colSpan={2}>{`£${booking.bookingIncome?.cost ?? currentBookingIncome?.cost}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={9}>Profit</TableCell>
              <TableCell colSpan={2}>{`£${booking.bookingIncome?.profit ?? currentBookingIncome?.profit}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoiceReportTable;
