import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { sec } from '../../auth/accessToken';
import { BaseDialogueProps } from 'models/index.model';
import { Booking } from 'models/booking.model';
import BookingDialogueContent from 'components/BookingDialogueContent';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import AlertSnackbar from 'components/AlertSnackbar';
import { AlertColor } from '@mui/material/Alert';
import { useUserPermissions } from 'utils/utils';
import { handleSnackbarClose } from 'utils/utils';
import { useGetAuditsQuery } from 'state/api';

export interface UpdateBookingDialogueProps extends BaseDialogueProps {
  id: string;
  booking: Booking;
}

const UpdateBookingDialogue: React.FC<UpdateBookingDialogueProps> = ({ handleClose, open, id, booking }) => {
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('create:bookings-export');
  const hasImportPermission = userPermissions.includes('create:bookings-import');

  const useFormReference = useForm<Booking>({
    defaultValues: booking,
  });
  const { handleSubmit } = useFormReference;
  useEffect(() => {
    useFormReference.reset(booking);
  }, [booking, useFormReference]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', id]);

  const onSubmit = async (data: Booking) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setSnackbarMessage('Booking Updated Successfully !');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
      auditLogsRefetch();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        const errorMessage = err.response?.data?.message || 'An error occurred';
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('An unexpected error occurred');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Update Booking Details
        </DialogTitle>
        <DialogContent>
          <BookingDialogueContent
            bookingToUpdate={booking}
            useFormReference={useFormReference}
            hasExportPermission={hasExportPermission}
            hasImportPermission={hasImportPermission}
          />
          <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText='Update' />
        </DialogContent>
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default UpdateBookingDialogue;
