import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Booking, BookingStatus } from 'models/booking.model';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import BarChart from 'components/charts/BarChart';

export interface ProfitLossDialogueProps extends BaseDialogueProps {
  booking: Booking;
  currentBookingIncome: Booking['bookingIncome'];
  currentBookingLoading: boolean;
}

const ProfitLossDialogue: React.FC<ProfitLossDialogueProps> = ({
  handleClose,
  open,
  booking,
  currentBookingIncome,
  currentBookingLoading,
}) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='md'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          Profit / Loss Statement
        </DialogTitle>
        <DialogContent>
          {booking.bookingStatus !== BookingStatus.Completed && (
            <Typography>
              These are temporary values based on current exchange rates. Final values will be updated according to
              exchange rates on the day of booking completion.
            </Typography>
          )}
          <BarChart
            savedBookingIncome={booking.bookingIncome}
            currentBookingIncome={currentBookingIncome}
            currentBookingLoading={currentBookingLoading}
          />
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default ProfitLossDialogue;
