import React, { useState } from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Button,
  useTheme,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Autocomplete,
} from '@mui/material';
import { sec } from '../../auth/accessToken';
import { currencies, bases, chargeBaseMapping, chargeBaseMappingKeys } from '../../dataAssets/constants';
import { generateMenuItemsFromArray } from '../../utils/generateMenuItemsFromArray';
import { BaseDialogueProps } from 'models/index.model';
import { Booking, Charge, Invoice } from 'models/booking.model';
import AlertSnackbar from 'components/AlertSnackbar';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { BaseIsolatedReRender } from 'utils/renderBase';
import { useGetAuditsQuery, useGetExpectedProfitQuery } from 'state/api';

export interface QuotationDialogueProps extends BaseDialogueProps {
  id: string;
  buyRates: Charge[];
  sellRates: Charge[];
}

const QuotationDialogue: React.FC<QuotationDialogueProps> = ({ handleClose, open, id, buyRates, sellRates }) => {
  const theme = useTheme();

  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', id]);
  const { refetch: expectedProfitRefetch } = useGetExpectedProfitQuery(id);

  const currentForm = useForm<Booking>({
    defaultValues: {
      buyRates: buyRates,
      sellRates: sellRates,
    },
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = currentForm;

  const {
    fields: buyFields,
    append: buyAppend,
    remove: buyRemove,
  } = useFieldArray({
    control,
    name: 'buyRates',
  });

  const {
    fields: sellFields,
    append: sellAppend,
    remove: sellRemove,
  } = useFieldArray({
    control,
    name: 'sellRates',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async (data: { buyRates: Charge[]; sellRates: Charge[] }) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${id}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      handleClose();
      setSnackbarOpen(true);
      auditLogsRefetch();
      expectedProfitRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {'Update Quotation'}
        </DialogTitle>
        <DialogContent>
          <form>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'} id='buy-charges-container'>
                <Typography variant='h5'>Buy Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => buyAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {buyFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'70fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <Controller
                        name={`buyRates.${index}.chargeName`}
                        control={control}
                        defaultValue={chargeBaseMappingKeys[0]}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            freeSolo
                            autoSelect
                            value={value}
                            onChange={(_, value) => onChange(value)}
                            options={chargeBaseMappingKeys}
                            renderInput={params => <TextField {...params} label='Charge Name *' />}
                          />
                        )}
                      />
                      <Controller
                        name={`buyRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        defaultValue={0}
                        {...register(`buyRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.buyRates?.[index]?.rate}
                        helperText={errors?.buyRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={currentForm as UseFormReturn<Invoice | Booking, any, Invoice | Booking>}
                        index={index}
                        chargeBaseMapping={chargeBaseMapping}
                        bases={bases}
                        formPath='buyRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => buyRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              m={'1rem 0'}
              overflow={'auto'}
              padding={'1rem'}
              border={'0.5px solid rgba(255, 255, 255, 0.12)'}
              borderRadius={'11px'}
            >
              <Box display={'flex'} justifyContent={'space-between'} id='sell-charges-container'>
                <Typography variant='h5'>Sell Charges</Typography>
                <Button
                  variant='contained'
                  type='button'
                  onClick={() => sellAppend({} as Charge)}
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    '&:hover': {
                      backgroundColor: theme.palette.secondary[500],
                    },
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box m={'1rem 0'}>
                {sellFields.map((item, index) => {
                  return (
                    <Box
                      display='grid'
                      gridTemplateColumns={'70fr 7fr 10fr 10fr 3fr'}
                      alignItems={'center'}
                      gap={'1rem'}
                      mt={'1rem'}
                      key={item.id}
                    >
                      <Controller
                        name={`sellRates.${index}.chargeName`}
                        control={control}
                        defaultValue={chargeBaseMappingKeys[0]}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            freeSolo
                            autoSelect
                            value={value}
                            onChange={(_, value) => onChange(value)}
                            options={chargeBaseMappingKeys}
                            renderInput={params => <TextField {...params} label='Charge Name *' />}
                          />
                        )}
                      />
                      <Controller
                        name={`sellRates.${index}.currency`}
                        control={control}
                        defaultValue={currencies[0]}
                        render={({ field }) => (
                          <TextField {...field} select autoComplete='off' label='Currency *'>
                            {generateMenuItemsFromArray(currencies)}
                          </TextField>
                        )}
                      />
                      <TextField
                        autoComplete='off'
                        type='number'
                        label='Value *'
                        defaultValue={0}
                        {...register(`sellRates.${index}.rate`, {
                          required: 'Value is required',
                        })}
                        error={!!errors?.sellRates?.[index]?.rate}
                        helperText={errors?.sellRates?.[index]?.rate?.message}
                      />
                      <BaseIsolatedReRender
                        form={currentForm as UseFormReturn<Invoice | Booking, any, Invoice | Booking>}
                        index={index}
                        chargeBaseMapping={chargeBaseMapping}
                        bases={bases}
                        formPath='sellRates'
                      />
                      <Button
                        variant='contained'
                        type='button'
                        onClick={() => sellRemove(index)}
                        sx={{
                          bgcolor: theme.palette.secondary[400],
                          '&:hover': {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </form>
        </DialogContent>{' '}
        <GeneralDialogueActions onClick={handleSubmit(onSubmit)} handleClose={handleClose} submitText='Update' />
      </Dialog>
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'success'}
        message={'Quotation Updated Successfully !'}
      />
    </Box>
  );
};

export default QuotationDialogue;
